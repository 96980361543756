import { useField } from 'formik';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import startOfDay from 'date-fns/startOfDay';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  notchedOutlineSize: {
    fontSize: '1.1rem',
  },
  iconButtonRoot: {
    color: theme.palette.primary.light,
    '&:hover': {
      color: theme.palette.primary.light,
    },
    legendLabelled: {
      '& > span': {
        paddingRight: 22,
        paddingLeft: 2,
      },
    },
  },
  iconButtonSizeSmall: {
    '& svg': {
      width: '1.05rem',
    },
  },
}));

interface KeyboardDateFieldProps {
  label: string;
  format: string;
  shrink: boolean;
  name: string;
  onChange?: () => void;
  formik?: boolean;
  value?: string;
}

const KeyboardDateField: FC<KeyboardDateFieldProps> = ({
  shrink = true,
  format = 'dd.MM.yyyy',
  onChange,
  value = '',
  formik = true,
  ...rest
}) => {
  const classes = useStyles();
  const commonProps: Partial<KeyboardDatePickerProps> = {
    disableToolbar: true,
    fullWidth: true,
    format,
    inputVariant: 'outlined',
    variant: 'inline',
    InputLabelProps: { shrink },
    InputProps: {
      classes: {
        notchedOutline: classes.notchedOutlineSize,
      }
    },
    KeyboardButtonProps: {
      size: 'small',
      edge: 'end',
      classes: {
        root: classes.iconButtonRoot,
        sizeSmall: classes.iconButtonSizeSmall,
      },
    },
  }
  if (formik) {
    const [field, meta, form] = useField(rest);

    return (
      <KeyboardDatePicker
        {...commonProps}
        error={Boolean(meta.error)}
        helperText={meta.error}
        {...field}
        onChange={onChange || ((date) => form.setValue(date instanceof Date ? startOfDay(date) : null, false))}
        {...rest}
      />
    );
  }

  return (
    <KeyboardDatePicker
      {...commonProps}
      onChange={onChange || (() => undefined)}
      value={value}
      {...rest}
    />
  )

};

export default KeyboardDateField;
