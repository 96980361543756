import { forwardRef, useRef, useState, useEffect } from 'react';
import {
  Tooltip,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { CrossIcon } from '../Icons';
import { useIsOverflown } from '../hooks/useIsOverflown';
import { useStyles } from './inputStyles';
import { InputLabel } from './InputLabel';
import { CopyIconButton } from '../CopyIconButton';

export const PseudoSelect = forwardRef(
  (
    {
      onClick,
      onChange,
      isOpen,
      label,
      description,
      value,
      required,
      styleProps,
      name,
      withLongValueTooltip,
      withLongLabelTooltip,
      errorMessage,
      placeholder,
      disabled,
      clearable,
      copyable,
      hideTriangle,
      contrastingLabel,
      onClickCopyIcon,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(value);
    const classes = useStyles({ ...styleProps, clearable, hideTriangle });
    const inputRef = useRef(null);
    const isOverflown = useIsOverflown(inputRef, value);

    const handleClearClick = (e) => {
      e.stopPropagation();
      onChange({ target: { value: [] } });
      setInputValue('');
    };

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    return (
      <Tooltip title={withLongValueTooltip && isOverflown ? value : ''}>
        <TextField
          ref={ref}
          variant="outlined"
          disabled={disabled}
          label={
            label ? (
              <InputLabel
                label={label}
                inputRef={inputRef}
                required={required}
                withLongLabelTooltip={withLongLabelTooltip}
                tooltip={description}
                contrastingLabel={contrastingLabel}
              />
            ) : null
          }
          name={name}
          inputRef={inputRef}
          error={!!errorMessage}
          helperText={errorMessage}
          classes={{
            root: isOpen
              ? classes.pseudoSelectOpen
              : classes.pseudoSelectClosed,
          }}
          InputLabelProps={{
            shrink: true,
            style: { pointerEvents: 'auto' },
          }}
          InputProps={{
            classes: {
              input: classes.pseudoSelectInput,
              notchedOutline: errorMessage
                ? classes.pseudoSelectErrorOutline
                : classes.pseudoSelectOutline,
            },
            endAdornment: inputValue && (clearable || copyable) && (
              <>
                {copyable && (
                  <InputAdornment position="end">
                    <CopyIconButton onClick={onClickCopyIcon} value={value} />
                  </InputAdornment>
                )}
                {clearable && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="reset field"
                      onClick={handleClearClick}
                      edge="end"
                      size="small"
                    >
                      <CrossIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
          onFocus={(e) => {
            e.target.blur();
          }}
          placeholder={placeholder}
          onClick={onClick}
          value={inputValue}
          onWheel={(e) => {
            e.target.scrollLeft = 0;
          }}
        >
          {inputValue}
        </TextField>
      </Tooltip>
    );
  }
);

PseudoSelect.propTypes = {
  isOpen: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  styleProps: PropTypes.object,
  withLongValueTooltip: PropTypes.bool,
  withLongLabelTooltip: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  copyable: PropTypes.bool,
  hideTriangle: PropTypes.bool,
  contrastingLabel: PropTypes.bool,
};

PseudoSelect.defaultProps = {
  isOpen: false,
  label: '',
  onClick: () => undefined,
  value: '',
};
