import { FC } from 'react';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';

import { QuestionIcon } from '../Icons/Question';
import styles from './InputTooltipIcon.module.scss';

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    marginBottom: 4,
    maxHeight: 646,
    maxWidth: 228,
    overflowY: 'auto',
    '& img': {
      marginTop: 4,
      maxWidth: '100%',
    },
    '& a': {
      color: theme.palette.primary.light,
    },
    '& ul, & ol': {
      paddingInlineStart: 16,
      marginBlockStart: 4,
      marginBlockEnd: 4,
    },
  },
}));

export interface InputTooltipIconProps {
  text?: string;
  className?: string;
  placement?: TooltipProps['placement'];
  textClassName?: string;
  disabled?: boolean;
}

/**
 *   Иконка с подсказкой
 */
export const InputTooltipIcon: FC<InputTooltipIconProps> = ({
  text,
  className,
  placement = 'top',
  textClassName,
  disabled,
}) => {
  const tooltipClasses = useStyles();

  return (
    <Tooltip
      classes={tooltipClasses}
      disableHoverListener={disabled}
      title={
        text ? (
          <span
            className={textClassName}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        ) : (
          <></>
        )
      }
      interactive
      placement={placement}
    >
      <span className={clsx(styles.icon, className)}>
        <QuestionIcon />
      </span>
    </Tooltip>
  );
};
