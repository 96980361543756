import { FC, SVGProps } from 'react';

interface PickUpPointIconProps extends SVGProps<SVGSVGElement> {}

export const PickUpPointIcon: FC<PickUpPointIconProps> = (props) => (
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.26 4.53c-.4 0-.77.23-.95.6-.4.84-1.09 2.4-1.27 3.3a1.6 1.6 0 1 0 3.16.37 1.6 1.6 0 1 0 3.2 0 1.6 1.6 0 1 0 3.2 0 1.6 1.6 0 1 0 3.2 0 1.6 1.6 0 1 0 3.16-.37c-.18-.9-.88-2.46-1.27-3.3-.18-.37-.55-.6-.95-.6H6.26ZM5.6 12.53c0-.59.48-1.06 1.07-1.06h10.66c.6 0 1.07.47 1.07 1.06v5.87c0 .59-.48 1.07-1.07 1.07h-2.66c-.6 0-1.07-.48-1.07-1.07v-2.13c0-.3-.24-.54-.53-.54h-2.14c-.3 0-.53.24-.53.54v2.13c0 .59-.48 1.07-1.07 1.07H6.67c-.6 0-1.07-.48-1.07-1.07v-5.87Z"
    />
  </svg>
);
