import { ReactNode } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';

function DatePickerProvider({ children }: { children: ReactNode }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
      {children}
    </MuiPickersUtilsProvider>
  );
}

export default DatePickerProvider;
