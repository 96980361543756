import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Loading } from '../Loading';
import { ActionsCell, ActionPropTypes } from './ActionsCell';
import TableCell from './TableCell';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TableRow from './TableRow';
import { useStyles } from './tableStyles';
import FakeActionsCell from './FakeActionsCell';
import getClassName from '../../utils/getClassName';

export const Table = ({
  className,
  columns,
  data,
  onRowClick,
  onRowMiddleClick,
  actions,
  rowIcon,
  showIcon,
  getCellClass,
  getRowClass,
  headerClassName,
  isLoading,
  loadingClassName,
  loadingCircularClassName,
  addLink,
}) => {
  const classes = useStyles();
  const hasActions = actions && actions.length > 0;
  const hasRowClickHandler = !!onRowClick;
  const hasRowMiddleClickHandler = !!onRowMiddleClick;

  const rowClickHandler = useCallback(
    (e) => {
      const { index } = e.currentTarget.dataset;

      if (!window.getSelection().toString() && onRowClick && index) {
        onRowClick(data[index], index);
      }
    },
    [onRowClick, data]
  );

  const rowMiddleClickHandler = useCallback(
    (e) => {
      const { index } = e.currentTarget.dataset;

      if (!window.getSelection().toString() && onRowMiddleClick && index && e.button === 1) {
        onRowMiddleClick(data[index], index);
      }
    },
    [onRowMiddleClick, data],
  );

  const renderBody = useMemo(() => {
    return data.map((row, i) => (
      <TableRow
        key={row.id}
        data-index={i}
        {...((hasRowClickHandler && !row?.disabledRowClick) ? { onClick: rowClickHandler } : {})}
        {...(hasRowMiddleClickHandler ? { onPointerDown: rowMiddleClickHandler } : {})}
        className={clsx(getClassName(getRowClass, row, i))}
      >
        {showIcon?.(row, i) && rowIcon}
        {columns.map((column) => (
          <TableCell
            key={column.id}
            value={row[column.id]}
            tooltipValue={row[column.tooltipKey]}
            renderer={column.cellRenderer}
            className={clsx(getClassName(getCellClass, row, i, column.id))}
            link={addLink && row?.link}
          />
        ))}
        {hasActions ? (
          <>
            {row.skipActions ? (
              <FakeActionsCell actions={actions} />
            ) : (
              <ActionsCell actions={actions} row={row} />
            )}
          </>
        ) : null}
      </TableRow>
    ));
  }, [
    data,
    hasRowClickHandler,
    hasRowMiddleClickHandler,
    showIcon,
    rowIcon,
    columns,
    hasActions,
    actions,
  ]);

  return (
    <div className={clsx(classes.table, className)}>
      {isLoading && (
        <Loading
          className={clsx(classes.loadingWrap, loadingClassName)}
          circularClassName={loadingCircularClassName}
        />
      )}
      <TableHead>
        <TableRow className={headerClassName}>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              value={column.label}
              renderer={column.headRenderer}
            />
          ))}

          {hasActions ? <FakeActionsCell actions={actions} /> : null}
        </TableRow>
      </TableHead>

      <TableBody>{renderBody}</TableBody>
    </div>
  );
};

const ColumnProptypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  cellRenderer: PropTypes.func,
  tooltipKey: PropTypes.string,
});

Table.propTypes = {
  /**
   *  Класс применяемый к верхнему узлу таблицы,
   */
  className: PropTypes.string,
  /**
   *  Массив содержащий список колонок в формате {id, label},
   *  где id - ключ отображаемого значения из элемента передаваемого в массиве data,
   *  label - наименование колонки
   */
  columns: PropTypes.arrayOf(ColumnProptypes),
  /**
   *  Массив объектов содержащих данные из которых будет формироваться таблица
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      cellRenderer: PropTypes.func,
    })
  ),
  /**
   *  Массив actions, структура: <br>
   *  tooltipText: PropTypes.string.isRequired, <br>
   *  icon: PropTypes.node.isRequired, <br>
   *  onClick: PropTypes.func, <br>
   *  color: PropTypes.oneOf( <br>['default', 'inherit', 'primary', 'secondary'] <br>), <br>
   *  isSubmittedState: PropTypes.func, <br>
   */
  actions: PropTypes.arrayOf(ActionPropTypes),
  /**
   *  Обработчик клика по строке,
   *  функция которая первым аргументом принимает элемент из массива data
   *  соответствующий строке по которой произошел клик
   */
  onRowClick: PropTypes.func,
  /**
   *  Иконка строки
   */
  rowIcon: PropTypes.node,
  /**
   *  Функция определяющая следует ли показывать иконку из rowIcon.<br>
   *  аргументы: <br>
   *  rowData - данные по строке из массива data <br>
   *  index - порядковый номер строки <br><br>
   *
   *  <b>Внимание!</b> Если иконку отображать не для всех строк, то  строки будут сдвигаться, хорошо видно в примере.
   *  Для корректного отображения необходимо вставлять пустую иконку для тех мест, где не должно быть иконки.
   */
  showIcon: PropTypes.func,
  /**
   *  Класс навешиваемый на шапку
   */
  headerClassName: PropTypes.string,
  /**
   *  Функция возвращающая класс который будет навешиваться на строку. <br>
   *  аргументы: <br>
   *  rowData - данные по строке из массива data <br>
   *  index - порядковый номер строки
   */
  getRowClass: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
   *  Функция возвращающая класс который будет навешиваться на ячейку. <br>
   *  аргументы: <br>
   *  rowData - данные по строке из массива data <br>
   *  index - порядковый номер строки
   *  key - ключ отвечающий по которому берется значение из rowData для этой ячейки
   */
  getCellClass: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /**
   * Flag для показа loader поверх таблицы
   * Class навешиваемый на loader-wrap и loader-circular
   */
  isLoading: PropTypes.bool,
  loadingClassName: PropTypes.string,
  loadingCircularClassName: PropTypes.string,
};
