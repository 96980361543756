import { FC, useCallback } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { CopyIcon, CopyIconButtonProps } from '@21vek/admin-front-components';

import styles from './CopyIconButton.module.css';

const CopyIconButton: FC<CopyIconButtonProps> = ({
  value,
  copyIcon,
  className,
  popupText = 'Значение скопировано',
  onClick,
}) => {
  const handleCopyButtonClick = useCallback(
    async (e) => {
      e.stopPropagation();
      await onClick?.(value, popupText);
    },
    [popupText, value]
  );

  return (
    <Tooltip title="Скопировать">
      <IconButton
        className={clsx(styles.copyIcon, className)}
        onClick={handleCopyButtonClick}
      >
        {copyIcon || <CopyIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default CopyIconButton;
